import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web'
import { useInView } from '@react-spring/web';
import DigitalNetwork from './DigitalNetwork';
import styles from '../styles/Hero.module.css';
import Button from './Button';

const Path = () => {
  // useInView hook to detect when the element is in the viewport
  const [ref, inView] = useInView({ triggerOnce: true });
  const [animationTriggered, setAnimationTriggered] = useState(false);


  // Calculate the total length of each path for animation
  const pathLengths = {
    path1: 200, // Approximate length of the first path
    path2: 400, // Approximate length of the second path
    path3: 400, // Approximate length of the third path
    path4: 150, // Approximate length of the fourth path
  };

  // Define a function to get the correct strokeDashoffset based on whether the animation has been triggered
  const getStrokeDashoffset = (pathLength) => {
    if (inView) {
      return 0;
    } else {
      return pathLength;
    }
  };

  // Spring animation for the first path
  const path1Style = useSpring({
    strokeDasharray: pathLengths.path1,
    strokeDashoffset: getStrokeDashoffset(pathLengths.path1),
    config: { duration: 1000 },
  });

  // Spring animation for the second path
  const path2Style = useSpring({
    strokeDasharray: pathLengths.path2,
    strokeDashoffset: getStrokeDashoffset(pathLengths.path2),
    config: { duration: 1500 },
    delay: 1000,
  });

  // Spring animation for the third path
  const path3Style = useSpring({
    strokeDasharray: pathLengths.path3,
    strokeDashoffset: getStrokeDashoffset(pathLengths.path3),
    config: { duration: 1500 },
    delay: 2500,
  });

  // Spring animation for the fourth path
  const path4Style = useSpring({
    strokeDasharray: pathLengths.path4,
    strokeDashoffset: getStrokeDashoffset(pathLengths.path4),
    config: { duration: 1000 },
    delay: 3900,
    onRest: () => {
      if (!animationTriggered) {
        setAnimationTriggered(true);
      }
    }
  });

  return (
    <div>
      <svg width="700" height="800" ref={ref}>
        {/* Vertical straight line then a curve angle to the right*/}
        <animated.path
          style={path1Style}
          d="M 0 0 Q 0 100 100 100"
          stroke="white"
          fill="transparent"
          strokeWidth="3"
        />

        {/* Text at the end of the path */}
        <text
          x="200"
          y="105"
          fill="white"
          fontSize="20"
          fontWeight="bold"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          Test de personnalité
        </text>

        { /* Horizontal line at the end of the path text, going to the right, then curves down from the right side, then curves back to the left */}
        <animated.path
          style={path2Style}
          d="M 300 100 Q 400 100 400 200 Q 400 300 300 300"
          stroke="white"
          fill="transparent"
          strokeWidth="3"
        />

        {/* Text at the end of the path */}
        <text
          x="190"
          y="305"
          fill="white"
          fontSize="20"
          fontWeight="bold"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          Analyse de vos atouts
        </text>

        { /* Horizontal line at the left end of the text, going to the left, then curves down from the left side, then curves back to the right */}
        <animated.path
          style={path3Style}
          d="M 80 300 Q 0 300 0 400 Q 0 500 80 500"
          stroke="white"
          fill="transparent"
          strokeWidth="3"
        />

        {/* Text at the end of the path */}
        <text
          x="225"
          y="505"
          fill="white"
          fontSize="20"
          fontWeight="bold"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          Recommandations sur-mesure
        </text>

        { /* Horizontal line at the right end of the text, going to the right, then curves down from the right side */}
        <animated.path
          style={path4Style}
          d="M 370 500 Q 450 500 450 600"
          stroke="white"
          fill="transparent"
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};

const Hero = () => {
  return (
    <div className={styles["hero"]}>
      <div className={styles["hero-background"]}><DigitalNetwork /></div>
      <div className={styles["hero-content-right"]}>
        <div className={styles["hero-text"]}>
          <h1 className={styles["hero-title-right"]}>Trouvez l'emploi <br />
            <span className={styles['white']}>qui <span className={styles["underlined"]}>vous</span> correspond</span></h1>
          <p className={styles["hero-description-right"]}>Avec Wanteed, découvrez l’opportunité professionnelle faite pour vous, en fonction de qui vous êtes vraiment.</p>
          <div className={styles["hero-buttons"]}>
            <Button text="Je trouve mon emploi idéal" variant='secondary-large' link='/culturefit' />
          </div>
        </div>
      </div>
      <div className={styles["hero-content-left"]}>
        <div className={styles["hero-text"]}>
          <h1 className={styles["hero-title-left"]}>Un parcours <span className='white'><span className={styles["underlined"]}>unique</span></span><br />
            pour une personnalité <span className='white'><span className="underlined">unique</span></span></h1>
          <div className={styles['path-container']}>
            <Path />
          </div>
          <div className={styles['svg-button-container']}>
            <Button text="Passer votre test" variant='secondary-large' link='/culturefit' />
          </div>
        </div>
      </div>
      <div>
        <h1 className={styles["hero-title-center"]}>Compatibilité parfaite, au-delà des compétences.</h1>
        <div className={styles["hero-band"]}>
          <div className={styles["card-container"]}>
            <div className={styles["band-card"]}>
              <h2 className={styles["band-card-title"]}>Compétences techniques</h2>
              <p className={styles["band-card-description"]}>L'ensemble de vos expériences professionnelles. Nous identifions vos points forts pour les mettre en lumière.</p>
              <Button text="Commencer le test" variant="secondary" link="/culturefit" className='band-card-button' />
            </div>
            <div className={styles["band-card"]}>
              <h2 className={styles["band-card-title"]}>Culture d'entreprise</h2>
              <p className={styles["band-card-description"]}>Nous évaluons votre affinité avec les valeurs et la culture des entreprises.</p>
              <Button text="Commencer le test" variant="secondary" link="/culturefit" className='band-card-button' s />
            </div>
            <div className={styles["band-card"]}>
              <h2 className={styles["band-card-title"]}>Atouts personnels</h2>
              <p className={styles["band-card-description"]}>Nous valorisons les qualités qui vous rendent unique.</p>
              <Button text="Commencer le test" variant="secondary" link="/culturefit" className='band-card-button' />
            </div>
            {/*
            <div className={styles["band-card"]}>
              <h2 className={styles["band-card-title"]}>Capacité d'intégration</h2>
              <p className={styles["band-card-description"]}>Nous vous aidons à trouver l’équipe où vous vous sentirez à votre place.</p>
              <Button text="Team fit" variant="secondary" link="/card4" className='band-card-button' />
            </div>
          */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;