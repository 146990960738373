// src/pages/HardSkillsPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import HardSkillForm from '../components/HardSkillForm';
//import HardSkillList from '../components/HardSkillList';
import ExperiencesModal from '../components/ExperiencesModal';
//import ExperienceForm from '../components/ExperienceForm';
import ExperienceList from '../components/ExperienceList';
import TestSwitcher from '../components/TestSwitcher';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/HardSkillsPage.module.css';  // Utilisation d'un module CSS

const HardSkillsPage = () => {
    const [token, setToken] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [experiences, setExperiences] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Store the file in state
    }

    useEffect(() => {
        const token = getSessionToken();
        setToken(token);
        console.log('token', token);
    }, []);

    const analyzeResume = async (e) => {
        e.preventDefault();
        setToken(getSessionToken());
        console.log("getSessionToken", token);
        if (!token) {
            const response = await fetch('https://dev.api.wanteed-job.com/credential/get-dummy-register', {
                method: 'GET',
            });
            if (response.ok) {
                const data = await response.json();
                setToken(data);
            } else {
                console.error('Failed to get token');
            }
        }
        setLoading(true);
        // Use POST /user/resume to analyze the resume
        e.preventDefault();
        const formData = new FormData();
        console.log('token', token);
        formData.append('file', file);
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/user/resume', {
                method: 'POST',
                headers: {
                    'session-key': token
                },
                body: formData
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Resume analysis:', data);
                setExperiences(data);
            } else {
                console.error('Failed to analyze resume');
            }
        }
        catch (error) {
            console.error('Error analyzing resume:', error);
        }
        setLoading(false);
        openModal();
    }

    /*
    const saveExperiences = async (formData) => {
        // If the user is not logged in, store the data in local storage
        const token = getSessionToken();
        if (localStorage.getItem('isRegistered') === 'false') {
            const hardSkills = JSON.parse(localStorage.getItem('hardSkills')) || [];
            hardSkills.push(formData);
            localStorage.setItem('hardSkills', JSON.stringify(hardSkills));
        }
        // If the user is logged in, send the data to the API
        else {
            // Push the experiences too
            formData.forEach(async (object) => {
                try {
                    const response = await fetch('https://dev.api.wanteed-job.com/experience/add', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'session-key': token
                        },
                        body: JSON.stringify({
                            title: object.experience.title,
                            company: object.experience.company,
                            description: object.experience.description,
                            duration: Number(object.experience.duration),
                            performance: object.experience.performance || "",
                            appreciation: object.experience.appreciation || ""
                        })
                    });
                    if (response.ok) {
                        console.log('Experience added successfully');
                    } else {
                        console.error('Failed to add experience');
                    }
                }
                catch (error) {
                    console.error('Error adding experience:', error);
                }

                // Push the hardskills in the experience
                object.hardskills.forEach(async (hardskill) => {
                    try {
                        const response = await fetch('https://dev.api.wanteed-job.com/hardskill/add', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'session-key': token
                            },
                            body: JSON.stringify({
                                hardskill: hardskill.hardskill,
                                time: hardskill.time,
                            })
                        });
                        if (response.ok) {
                            console.log('Hardskill added successfully');
                        } else {
                            console.error('Failed to add hardskill');
                        }
                    }
                    catch (error) {
                        console.error('Error adding hardskill:', error);
                    }
                });
            });
        }
    }
    */

    return (
        <div className={styles.hardSkillsPage}>
            <TestSwitcher activeTest="hardskills" />
            {//file != null && (localStorage.getItem('isRegistered') === 'false' ? (
                true ? (
                    // A button to let the user register since he finished the tests
                    <button onClick={() => navigate('/register')} className={styles.registerButton}>Finaliser mon inscription</button>
                ) : null}
            {isModalOpen && <ExperiencesModal experiences={experiences} onClose={closeModal} />}
            <div className={styles.container}>
                <div className={styles.section}>
                    <h2>Importez votre CV</h2>
                    <p>Importez votre CV pour extraire vos hardskills automatiquement</p>
                    {loading && <p>Analyse de votre CV en cours... Cela peut prendre jusqu'à une minute.</p>}
                    <form onSubmit={analyzeResume}>
                        <input type="file" onChange={handleFileChange} />
                        <button>Importer et analyser</button>
                    </form>
                </div>
                {/*
                <div className={styles.section}>
                    <h2>Vos hardskills</h2>
                    <HardSkillList />
                </div>
                {/*
                <div className={styles.section}>
                    <h2>Ajouter un hardskill manuellement</h2>
                    <HardSkillForm />
                </div>
                */}
                {/*
                <div className={styles.section}>
                    <h2>Ajouter une expérience</h2>
                    <ExperienceForm experiences={experiences} />
                </div>
            </div>
            */}
            </div>
            <div className={styles.container}>
                <div className={styles.section}>
                    <h2>Your Experiences</h2>
                    <ExperienceList />
                </div>
            </div>
        </div>
    );
};

export default HardSkillsPage;
