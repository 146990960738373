import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { AuthContext } from './AuthContext';
import { getSessionToken} from '../utils/Auth';
import styles from '../styles/Navbar.module.css';
import logo from "../assets/logo.png";

function Navbar() {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // On page load, check if the user is logged in
    useEffect(() => {
        getSessionToken() ? setIsLoggedIn(true) : setIsLoggedIn(false)
    });

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={styles["navbar"]}>
            <img src={logo} alt="Logo" className={styles["logo"]} onClick={() => navigate('/')} />
            <div className={`${styles["menu-icon"]} ${isMenuOpen ? styles['open'] : ''}`} onClick={toggleMenu}>
                <div className={styles["burger"]}></div>
            </div>
            <ul className={`${styles["nav-links"]} ${isMenuOpen ? styles['active'] : ''}`}>
                {isLoggedIn ? (
                        <li><Button text="Explorer les offres" link="/jobs" variant='outline'/></li>
                    ) : (
                        <li><Button text="Accueil" link="/" variant='outline'/></li>
                    )}
                {isLoggedIn ? (
                        <li><Button text="Vos Annonces" link="/admin" variant='outline'/></li>
                    ) : (
                        <li><Button text="Recruter sur Wanteed ?" link="/company" variant='outline'/></li>
                    )}
                {isLoggedIn && <li><Button text="Mon Profil" link="/profile" variant="secondary" /></li>}
                {isLoggedIn ? (
                    <li>
                        {/* <Button text="Déconnexion" onClick={handleLogout} variant='inverted'/> */}
                        </li>
                ) : (
                    <li><Button text="Connexion" link="/login" variant='inverted'/></li>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;
