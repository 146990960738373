import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/SoftSkillTest.module.css';

const SoftSkillTest = () => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [token, setToken] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [canGoToNextTest, setCanGoToNextTest] = useState(false);

    useEffect(() => {
        const token = getSessionToken();
        if (!token) {
            fetchDummyRegister();
        } else {
            setToken(token);
        }
        const storedAnswers = JSON.parse(localStorage.getItem('softskillAnswers')) || [];
        setAnswers(storedAnswers);
    }, []);

    const fetchDummyRegister = async () => {
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/credential/get-dummy-register', {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setToken(data);
            console.log('token', data);
            localStorage.setItem('isRegistered', 'false');
        } catch (error) {
            setError('Failed to get session key. Please try again.');
            setLoading(false);
        }
    };

    const fetchQuestion = useCallback(async () => {
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/questions/get-softskill?tunnel=false', {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setQuestion(data);
            setLoading(false);
        } catch (error) {
            setError('Failed to load question. Please try again. (You may have answered all questions)');
            setLoading(false);
        }
    }, [token]);

    const fetchQuestionsCount = async () => {
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/questions/count-softskill?tunnel=false', {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            localStorage.setItem('softskillQuestionsCount', data.answered);
            if (data.answered >= 5) {
                setCanGoToNextTest(true);
            }
        } catch (error) {
            console.error('Failed to fetch questions count');
        }
    };

    const fetchThemesCount = async () => {
        if (!token) {
            fetchDummyRegister();
        }
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/questions/count-softskill?tunnel=true', {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            localStorage.setItem('softskillThemesCount', data.answered);
            if (data.answered >= 3) {
                setCanGoToNextTest(true);
            }
        } catch (error) {
            console.error('Failed to fetch themes count');
        }
    };

    useEffect(() => {
        if (localStorage.getItem('softskillAnswers')) {
            const nbQuestions = JSON.parse(localStorage.getItem('softskillAnswers')).length;
            if (nbQuestions >= 5) {
                setCanGoToNextTest(true);
            }
        }
        if (localStorage.getItem('softskillQuestionsCount')) {
            const nbQuestions = JSON.parse(localStorage.getItem('softskillQuestionsCount'));
            if (nbQuestions >= 5) {
                setCanGoToNextTest(true);
            }
        }
        if (localStorage.getItem('softskillThemesCount')) {
            const nbThemes = JSON.parse(localStorage.getItem('softskillThemesCount'));
            if (nbThemes >= 3) {
                setCanGoToNextTest(true);
            }
        }
    }, []);

    useEffect(() => {
        if (token && !question) {
            fetchQuestion();
            //fetchQuestionsCount();
            //fetchThemesCount();
        }
    }, [token, fetchQuestion, question]);

    const handleAnswer = async (value) => {
        if (localStorage.getItem('isRegistered') === 'false') {
            const newAnswers = [...answers, { questionID: question.question_id, value: value }];
            setAnswers(newAnswers);
            localStorage.setItem('softskillAnswers', JSON.stringify(newAnswers));
            const nbQuestions = JSON.parse(localStorage.getItem('softskillAnswers')).length;
            if (nbQuestions >= 5) {
                setCanGoToNextTest(true);
            }
            fetchQuestion();
            return;
        }
        try {
            const response = await fetch('https://dev.api.wanteed-job.com/questions/answer-softskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
                body: JSON.stringify({
                    questionID: question.question_id,
                    value: value,
                    time: 0,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            fetchQuestion();
            fetchQuestionsCount();
            fetchThemesCount();
        } catch (error) {
            setError('Failed to submit answer. Please try again.');
        }
    };

    return (
        <div className={styles["softskill-test"]}>
            <TestSwitcher activeTest="softskills" />
            <h1 className={styles["test-title"]}>Soft Skill Test</h1>
            <p className={styles["test-description"]}>Cliquez sur une bulle pour indiquer quelle affirmation vous correspond le mieux.<br />La bulle au centre indique que vous êtes neutre.</p>
            {loading ? (
                <p className={styles["loading"]}>Loading...</p>
            ) : error ? (
                <p className={styles["error"]}>{error}</p>
            ) : (
                question && (
                    <div className={styles["question-container"]}>
                        <div className={styles["question-options"]}>
                            <p className={styles["question"]}>{question.question_a}</p>
                            <p className={styles["question"]}>{question.question_b}</p>
                        </div>
                        <div className={styles["scale"]}>
                            <span onClick={() => handleAnswer(-3)} className={styles["leftmost"]}></span>
                            <span onClick={() => handleAnswer(-2)} className={styles["left"]}></span>
                            <span onClick={() => handleAnswer(-1)} className={styles["left"]}></span>
                            <span onClick={() => handleAnswer(0)} className={styles["neutral"]}></span>
                            <span onClick={() => handleAnswer(1)} className={styles["right"]}></span>
                            <span onClick={() => handleAnswer(2)} className={styles["right"]}></span>
                            <span onClick={() => handleAnswer(3)} className={styles["rightmost"]}></span>
                        </div>
                    </div>
                )
            )}
            {!loading && canGoToNextTest && localStorage.getItem('isRegistered') === 'false' && (
                <div className={styles["next-test-container"]}>
                    <button
                        className={styles["next-test-button"]}
                        onClick={() => window.location.href = '/hardskills'}
                    >
                        Continuer vers l'ajout des hard skills
                    </button>
                </div>
            )}
        </div>
    );
};

export default SoftSkillTest;
