import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetch('https://dev.api.wanteed-job.com/credential/get-logged', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': `${token}`
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        setIsLoggedIn(true);
                    } else if (response.status === 401) {
                        localStorage.removeItem('token');
                        setIsLoggedIn(false);
                    }
                });
        }
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};
