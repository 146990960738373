import React, { useState } from 'react';
import styles from '../styles/Modal.module.css';
import ExperienceForm from './ExperienceForm';

const ExperiencesModal = ({ experiences, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    const handleValidate = () => {
        console.log("Called handleValidate");
        if (currentIndex < experiences.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setIsOpen(false);
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <>
            {experiences && experiences.length > 0 && (
                <div className={styles["modal-overlay"]}>
                    <div className={styles["modal-content"]}>
                        <button className={styles["close-button"]} onClick={onClose}>
                            &times;
                        </button>
                        <ExperienceForm
                            experience={experiences[currentIndex]}
                            onValidate={handleValidate}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ExperiencesModal;
