// src/components/ProtectedRoute.js
import { useNavigate } from 'react-router-dom';
import { getSessionToken, removeSessionToken } from '../utils/Auth';

const ProtectedRoute = ({ children, redirectTo = "/login" }) => {
    const token = getSessionToken();
    const navigate = useNavigate();
    console.log("Verifying token {token}");
    console.log(token);

    if (!token) {
        navigate(redirectTo);
    }

    // Call the API to verify the token
    fetch('https://dev.api.wanteed-job.com/credential/get-logged', {
        method: 'GET',
        headers: {
            'session-key': `${token}`
        }
    })
        .then(response => {
            if (response.status === 401) {
                console.log("Token is invalid");
                removeSessionToken();
                navigate(redirectTo);
            }
        })

    return children;
};

export default ProtectedRoute;