// src/pages/Register.js
import React, { useState } from "react";
import styles from "../styles/Register.module.css";
import Notification from "../components/Notification";
import { useNavigate } from "react-router-dom";
import { setSessionToken } from "../utils/Auth";

function Register() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [notification, setNotification] = useState(null);

    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        // Placeholder for the API call
        console.log("Registering with", { email, password });

        fetch('https://dev.api.wanteed-job.com/credential/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, password: password, first_name: firstName, last_name: lastName }),
        })
            .then(data => {
                if (data.error) {
                    setNotification({ type: 'error', message: data.error });
                } else {
                    setNotification({ type: 'success', message: 'Account created successfully!' });
                    setSessionToken(data);
                    navigate('/validate?email=' + email);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setNotification({ type: 'error', message: 'An error occurred.' });
            });
    };

    return (
        <div className={styles["registerCard"]}>
            <div className={styles["registerForm"]}>
                <form onSubmit={handleRegister}>
                    <h1>Inscription</h1>
                    <p>Créez votre compte Wanteed !</p>
                    <div className={styles["rowContainer"]} style={{ marginTop: "50px" }}>
                        <div className={styles["inputRow"]} style={{ width: "48%" }}>
                            <input
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="inputGroup"
                                placeholder="Prénom"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            /><br />
                        </div>
                        <div className={styles["inputRow"]} style={{ width: "48%" }}>
                            <input
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="inputGroup"
                                placeholder="Nom"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            /><br />
                        </div>
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="inputGroup"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        /><br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="inputGroup"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        /><br />
                    </div>
                    <div className={styles["inputRow"]}>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="inputGroup"
                            placeholder="Confirmer le mot de passe"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        /><br />
                    </div>
                    <button type="submit">S'identifier</button>
                    <p>Déjà un compte ? <a href="/login">Se connecter</a></p>
                </form>
            </div>
            {notification && <Notification type={notification.type} message={notification.message} />}
        </div>
    );
}

export default Register;
