import React from 'react';
import styles from '../styles/GenericToggleButton.module.css';

const GenericToggleButton = ({ view, setView, buttons }) => {
    return (
        <div className={styles["toggle-button"]}>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    className={view === button.view ? styles['active'] : ''}
                    onClick={() => setView(button.view)}
                >
                    {button.label}
                </button>
            ))}
        </div>
    );
}

export default GenericToggleButton;
