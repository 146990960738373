import styles from '../styles/Offer.module.css';

const Offer = ({ jobData }) => {
    let date = new Date(jobData.release_date);

    const formattedDescription = jobData.description.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>;
    });

    return (
        <div className={styles.offerContainer}>
            <h1 className={styles.title}>{jobData.title}</h1>

            <div className={styles["section-h"]}>
                <div className={styles["section-v"]}>
                    <p><strong>Type de contrat :</strong> {jobData.type}</p>
                    <p><strong>Salaire :</strong> {parseInt(jobData.salary)}€</p>
                    <p><strong>Date de publication :</strong> {date.getDay()} / {date.getMonth()} / {date.getFullYear()}</p>
                </div>

                <div className={styles["section-v"]}>
                    <p><strong>Entreprise :</strong> {jobData.company}</p>
                    <p><strong>Localisation :</strong> {jobData.city}, {jobData.country}</p>
                </div>
            </div>
            <div className={styles.description}>
                <p><strong>Description de l'offre :</strong><br/><br/>{formattedDescription}</p>
            </div>
        </div>
    );
};

export default Offer;
