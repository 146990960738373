// src/pages/Invite.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Invite.module.css';

const Invite = () => {
    const navigate = useNavigate();

    const [linkId, setLinkId] = useState('');
    const [offerInfo, setOfferInfo] = useState({})

    const handleStartTest = () => {
        navigate('/culturefit');
    }

    useEffect(() => {
        // Fetch the linkId from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const extractedLinkId = urlParams.get('linkId');
        if (extractedLinkId) {
            setLinkId(extractedLinkId);
            localStorage.setItem('linkId', extractedLinkId);
        }
    }, []);

    useEffect(() => {
        // Fetch the offer info from the API only if linkId is not empty
        if (linkId) {
            fetch(`https://dev.api.wanteed-job.com/job/get-job-from-link?link_id=${linkId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    setOfferInfo(data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [linkId]);

    return (
        <div className={styles["intro"]}>
            <h2>Dites nous en plus sur vous pour postuler à cette offre !</h2>
            <div className={styles['offer-details']}>
                <p>{offerInfo.title} chez {offerInfo.company} ({offerInfo.city}, {offerInfo.type})</p>
            </div>
            <div className={styles['instructions']}>
                <p>Vous êtes sur le point de postuler à une offre d'emploi. Pour mieux vous connaître, nous vous proposons de répondre à quelques questions.</p>
                <p>Cela ne prendra que quelques minutes, et nous permettra de vous proposer d'autres offres d'emploi qui vous correspondent.</p>
                <button onClick={handleStartTest}>Commencer le culture fit</button>
            </div>
            <div className={styles['login']}>
                <p>Vous avez déjà un compte ?</p>
                <button onClick={() => navigate('/login')}>Connectez-vous pour postuler directement</button>
            </div>
        </div >
    );
};

export default Invite;