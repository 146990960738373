import React from "react";
import GenericToggleButton from "../components/GenericToggleButton";
import PersonaPage from "./PersonaPage";
import Profile from "../components/Profile";

const ProfilePage = () => {
    const [view, setView] = React.useState("persona");
    const buttons = [
        { view: "profile", label: "Profil" },
        { view: "persona", label: "Persona" }
    ];

    return (
        <div>
            <GenericToggleButton view={view} setView={setView} buttons={buttons} />
            {view === "persona" && <PersonaPage />}
            {view === "profile" && <Profile />}
        </div>
    );
}

export default ProfilePage;