import React, { useState, useEffect } from 'react';
import styles from '../styles/Form.module.css';
import { getSessionToken } from '../utils/Auth';

const ExperienceForm = ({ experience, onValidate }) => {
    const [formData, setFormData] = useState({
        title: experience.experience.title || '',
        duration: Number(experience.experience.duration) || 0,
        description: experience.experience.description || '',
        company: experience.experience.company || '',
    });
    const sessionKey = getSessionToken();

    useEffect(() => {
        setFormData({
            title: experience.experience.title || '',
            duration: Number(experience.experience.duration) || 0,
            description: experience.experience.description || '',
            company: experience.experience.company || '',
        });
    }, [experience]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isRegistered = localStorage.getItem('isRegistered') === 'true';
        if (!isRegistered) {
            // If the user is not registered, store the experience in localStorage
            const experiences = JSON.parse(localStorage.getItem('experiences')) || [];
            experiences.push(formData);
            localStorage.setItem('experiences', JSON.stringify(experiences));
        } else {
            // If the user is registered, send the experience to the API
            try {
                const response = await fetch('https://dev.api.wanteed-job.com/experience/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'session-key': sessionKey
                    },
                    body: JSON.stringify(formData)
                });
                if (response.ok) {
                    //const newExperience = await response.json();
                } else {
                    console.error('Failed to add experience');
                }
            } catch (error) {
                console.error('Error adding experience:', error);
            }
        }
        onValidate();
        console.log("Called onValidate");
    };

    return (
        <form className={styles["form"]} onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
                <label>Titre :</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} required placeholder='Responsable Marketing' />
            </div>
            <div className={styles["form-group"]}>
                <label>Durée (années, mettez 0 pour moins d'un an):</label>
                <input type="number" name="duration" value={formData.duration} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Description:</label>
                <textarea name="description" value={formData.description} onChange={handleChange} required placeholder='Développement de la stratégie marketing...'></textarea>
            </div>
            <div className={styles["form-group"]}>
                <label>Entreprise :</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange} required />
            </div>
            <button type="submit" className={styles["button"]}>Valider</button>
        </form>
    );
};

export default ExperienceForm;
